const Pusher = require("pusher-js");
export default function pusher() {
    return (store) => {
        let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
            cluster: "us3",
        });
        let channel = pusher.subscribe("upload-channel");
        // Event is broadcast after order is created and online application created
        channel.bind("uploaded-application", ({ application }) => {
            store.dispatch("Uploads/uploadedApplication", application);
        });
        // Event is broadcast after order is created and online application created
        channel.bind("uploaded-application-error", ({ error }) => {
            store.dispatch("Uploads/uploadedApplicationError", error);
        });
    };
}
