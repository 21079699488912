<template>
    <v-app id="psi">
        <component
            v-if="$route.meta.layout"
            :is="`${$route.meta.layout}-layout`"
        ></component>
    </v-app>
</template>

<script>
export default {
    name: "App",
    components: {
        AuthLayout: () => import("./layouts/auth/AuthLayout"),
        PrintLayout: () => import("./layouts/print/PrintLayout"),
    },
    data: () => ({}),
    computed: {},
};
</script>

<style>
</style>

