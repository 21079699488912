import httpClient from "@api/client";

const API_EXTRACT_ZIP = "/uploads/media/extract/zip";
const API_EXTRACT_PDF = "/uploads/media/extract/pdf";
const API_STORE_APPLICATION = "/uploads/applications";
const API_STORE_APPLICANTS = "/uploads/applicants/multiple";
const API_FILL_SECTIONS = "/uploads/media/fill";
const API_GET_APPLICATIONS = "/uploads/applications";
const API_UPDATE_APPLICATION = "/uploads/applications";
const API_GET_SCREENERS = "/screeners";

export default {
    async extractZip(filepath, uuid) {
        let res = await httpClient.post(API_EXTRACT_ZIP, {
            filepath,
            uuid,
        });
        return res.data.data;
    },
    async extractPdf(filepath, splits) {
        let res = await httpClient.post(API_EXTRACT_PDF, {
            filepath,
            splits,
        });
        return res.data.data;
    },

    async getApplications(page, perPage) {
        let res = await httpClient.get(
            `${API_GET_APPLICATIONS}?page=${page}&per_page=${perPage}`
        );
        // Return data which includes the pagination meta and applications
        return res.data;
    },

    async storeApplication(application) {
        let res = await httpClient.post(API_STORE_APPLICATION, application);
        return res.data.data;
    },
    async storeApplicants(applicants) {
        let res = await httpClient.post(API_STORE_APPLICANTS, applicants);
        return res.data.data;
    },
    async fill(sections, required) {
        let res = await httpClient.post(API_FILL_SECTIONS, {
            required,
            sections,
        });
        return res.data;
    },
    async getScreeners() {
        let res = await httpClient.get(API_GET_SCREENERS);
        return res.data.data;
    },
    async updateApplication(id, assignedTo) {
        let res = await httpClient.put(`${API_UPDATE_APPLICATION}/${id}`, {
            assigned_to: assignedTo,
        });
        return res.data.data;
    },
};
