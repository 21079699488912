const componentHosts = {
    uploads: process.env.VUE_APP_UPLOADS_HOST,
    apply: process.env.VUE_APP_APPLY_HOST,
    portal: process.env.VUE_APP_PORTAL_HOST,
    messages: process.env.VUE_APP_MESSAGES_HOST,
    rentwell: process.env.VUE_APP_RENTWELL_HOST,
};

export default {
    sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    },
    loginUrl(returnUrl = "") {
        // Can't use window.location here due to DOM security violation in local dev
        const url = new URL(window.location.href);
        // need to replace the subdomain with "login" for staging, production
        const subdomain =
            url.hostname.split(".").pop() === "test" ? "portal-api" : "login";
        const hostname = url.hostname.replace(
            url.hostname.split(".").shift(),
            subdomain
        );

        return returnUrl.length === 0
            ? `https://${hostname}/login`
            : `https://${hostname}/login?return=${returnUrl}`;
    },
    returnUrl(query = "") {
        const returnUrl = `${window.location.protocol}//${window.location.host}`;
        return query.length === 0 || query === "/"
            ? returnUrl
            : `${returnUrl}&query=${encodeURIComponent(query)}`;
    },
    buildUrl(component, path, params = {}) {
        let query = "";
        if (Object.keys(params).length > 0) {
            query =
                "?" +
                Object.keys(params)
                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                    .join("&");
        }
        return `${componentHosts[component]}/${path}${query}`;
    },
    // eslint-disable-next-line no-unused-vars
    menuUrl(item) {
        // eslint-disable-next-line no-unused-vars
        const subdomain = window.location.hostname.split(".")[0];
        const {
            link: { to = "", href = "" },
        } = item;
        return item.subdomain === subdomain ? { to } : { href };
    },
};
