import utils from "@/utils";

export const routes = [
    {
        name: "Login",
        path: "/login",
        meta: {
            requiresAuth: false,
            keepAlive: false,
            layout: "",
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
            const {
                params: { returnUrl = "" },
            } = to;
            const loginUrl = utils.loginUrl(returnUrl);
            window.location.replace(loginUrl);
        },
    },
     {
        name: "Unauthorized",
        path: "/unauthorized",
        meta: {
            requiresAuth: false,
            keepAlive: false,
            layout: "public",
        },
        component: () => import("@components/users/views/UnauthorizedRequest"),
    },
    {
        path: "/",
        redirect: "/uploads/dashboard",
    },
    {
        name: "Uploads Dashboard",
        path: "/uploads/dashboard",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () =>
            import(
                "@/components/uploads/components/ApplicationUploadsDashboard"
            ),
    },
    {
        name: "Uploads Applications",
        path: "/uploads/apps",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () => import("@/components/uploads/views/UploadHistory"),
    },
    {
        name: "Uploads Start",
        path: "/uploads/start",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () => import("@/components/uploads/views/ApplicationStart"),
    },
    {
        name: "Component Utils",
        path: "/uploads/utils",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () =>
            import("@/components/uploads/components/Utils/ComponentDebug"),
    },
];
