// import the api endpoints
import api from "../api";

const initialState = () => ({
    applications: [],
    pagination: {}, // Pagination meta data
    loading: false,
    currentApplication: {},
    currentApplicants: [],
    screeners: [],
});

const state = initialState();

const getters = {
    applications: (state) => state.applications,
    pagination: (state) => state.pagination,
    loading: (state) => state.loading,
    currentApplication: (state) => state.currentApplication,
    currentApplicants: (state) => state.currentApplicants,
    screeners: (state) => state.screeners,
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    error({ commit }) {
        console.log("Error action in uploads module");
        // if we get an error broadcast, cancel loading
        commit("SET_LOADING", false);
    },
    async setError({ commit }, error) {
        console.log("Set Error with %s", error);
        commit("SET_ERROR", error, { root: true });
    },
    async getScreeners({ commit }) {
        try {
            commit("SET_LOADING", true);
            let data = await api.getScreeners();
            commit("SET_SCREENERS", data);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_LOADING", false);
            commit("SET_ERROR", error, { root: true });
        }
    },
    async getApplications({ commit }, paginate) {
        try {
            commit("SET_LOADING", true);
            let data = await api.getApplications(
                paginate.page,
                paginate.perPage
            );
            commit("SET_APPLICATIONS", data.data);
            commit("SET_PAGINATION", data.meta.pagination);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_LOADING", false);
            commit("SET_ERROR", error, { root: true });
        }
    },
    async storeApplication({ commit }, application) {
        try {
            commit("SET_LOADING", true);
            let data = await api.storeApplication(application);
            commit("SET_CURRENT_APPLICATION", data);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_LOADING", false);
            commit("SET_ERROR", error, { root: true });
        }
    },
    async storeApplicants({ commit }, applicants) {
        try {
            commit("SET_LOADING", true);
            let data = await api.storeApplicants(applicants);
            commit("SET_CURRENT_APPLICANTS", data);

            // We will set loading to false in the uploadedApplication action called from an async pusher channel event
        } catch (error) {
            commit("SET_LOADING", false);
            commit("SET_ERROR", error, { root: true });
        }
    },
    async resetApplication({ commit }) {
        commit("SET_CURRENT_APPLICATION", {});
        commit("SET_CURRENT_APPLICANTS", []);
    },

    async uploadedApplication({ commit, dispatch }, application) {
        if (application.id === state.currentApplication.id) {
            dispatch(
                "Snackbar/showMessage",
                {
                    content: `Application successfully uploaded`,
                    color: "secondary",
                },
                { root: true }
            );

            commit("SET_CURRENT_APPLICANTS", application.applicants);
            commit("SET_LOADING", false);
        }
    },

    async uploadedApplicationError({ commit }, error) {
        if (error.application.id === state.currentApplication.id) {
            commit("SET_ERROR", error.message, { root: true });
            commit("SET_LOADING", false);
        }
    },

    async updateApplication({ commit, dispatch }, { id, assignedTo }) {
        try {
            let data = await api.updateApplication(id, assignedTo);
            commit("SET_ASSIGNED_TO", data);
            dispatch(
                "Snackbar/showMessage",
                {
                    content: `Successfully assigned ${data.assigned_to.name}`,
                    color: "secondary",
                },
                { root: true }
            );
            // We will set loading to false in the uploadedApplication action called from an async pusher channel event
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_APPLICATIONS: (state, payload) => {
        state.applications = payload;
    },
    SET_PAGINATION: (state, payload) => {
        state.pagination = payload;
    },
    SET_CURRENT_APPLICATION: (state, payload) => {
        state.currentApplication = payload;
    },
    SET_CURRENT_APPLICANTS: (state, payload) => {
        state.currentApplicants = payload;
    },
    SET_ASSIGNED_TO: (state, payload) => {
        const index = state.applications.findIndex(
            (item) => item.id === payload.id
        );
        let application = Object.assign({}, state.applications[index]);
        application.assigned_to = payload.assigned_to;
        state.applications.splice(index, 1, application);
    },

    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
    SET_SCREENERS: (state, payload) => {
        state.screeners = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
